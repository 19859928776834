import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import {
  pass_type,
  transit_pass_type,
  two_wheeler_pass_type,
} from "@utils/constant";
import {
  createDriverSelector,
  create_condemnation,
  updateDriverSelector,
  update_driver,
  create_driver,
  get_one_driver,
  getOneDriverSelector,
  update_one_driver_clear,
  create_one_driver_clear,
  get_DriverList,
  get_DistrictList,
  getDistrictListSelector,
  get_Departments,
  get_designation,
  getDesignationListSelector,
  getDepartmentListSelector,
} from "@services/redux";
import { Button, Checkbox, Row } from "antd";
import { useHistory, useParams } from "react-router-dom";
import fileUpload from "@helpers/fileupload";
import { showToast } from "@helpers/toast";
import { Form as AntdForm } from "antd";
import moment from "moment";

const DriverDetails = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [actionItem, setActionItem] = useState({});
  const [transitShow, setTransitShow] = useState(false);
  const [visitors, setVisitors] = useState(false);
  const [is_political_driver, setPoliticalDriver] = useState(false);


  const { t } = useTranslation();

  const { items: districts } = useSelector(getDistrictListSelector);
  const { items: designations } = useSelector(getDesignationListSelector);

  const { items: department } = useSelector(getDepartmentListSelector);

  const {
    loading: updateLoading,
    status: updateStatus,
    error: updateError,
  } = useSelector(updateDriverSelector);
  const {
    loading: createLoading,
    status: createStatus,
    error: createError,
  } = useSelector(createDriverSelector);
  const { item: driver } = useSelector(getOneDriverSelector);
  const [is_motor_cycle_messager, set_is_motor_cycle_messager] = useState(false);

  const handleSubmit = (values) => {
    values.photo = values.photo?.[0]?.url;
    values.go_file = values.go_file?.[0]?.url;

    values.id_proof = values.id_proof?.[0]?.url;
    // values?.address === {} && delete values.address;
    // delete values.address;
    console.log("values", values);
    if (id) {
      let update_id = id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_driver({
          id: update_id,
          data: values,
        })
      );
    } else {
      dispatch(
        create_driver({
          data: values,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(get_DistrictList());
    dispatch(get_Departments({}));
    dispatch(get_designation({}));
  }, []);

  useEffect(() => {
    if (updateError === "Failure") {
      showToast({
        type: "error",
        message: `${t("Something went wrong!")}`,
      });

      dispatch(update_one_driver_clear());
    } else if (
      updateError?.includes(
        "Unique constraint failed on the fields: (`mobile`)"
      )
    ) {
      showToast({
        type: "error",
        message: `${t("Mobile Number already exists")}`,
      });

      dispatch(get_DriverList());
      dispatch(update_one_driver_clear());
    } else if (
      updateError?.includes(
        "Unique constraint failed on the fields: (`aadhar`)"
      )
    ) {
      showToast({
        type: "error",
        message: `${t("Aadhar Number already exists")}`,
      });

      dispatch(get_DriverList());
      dispatch(update_one_driver_clear());
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Driver")} ${t("update_successfully")}`,
      });
      history.goBack();

      dispatch(update_one_driver_clear);
      dispatch(get_DriverList());
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Driver")} ${t("create_successfully")}`,
      });

      dispatch(get_DriverList());
      dispatch(create_one_driver_clear());
      history.goBack();
    } else if (
      createError?.includes(
        "Unique constraint failed on the fields: (`mobile`)"
      )
    ) {
      showToast({
        type: "error",
        message: `${t("Mobile Number already exists")}`,
      });

      dispatch(get_DriverList());
      dispatch(create_one_driver_clear());
    } else if (
      createError?.includes(
        "Unique constraint failed on the fields: (`aadhar`)"
      )
    ) {
      showToast({
        type: "error",
        message: `${t("Aadhar Number already exists")}`,
      });

      dispatch(get_DriverList());
      dispatch(create_one_driver_clear());
    } else if (createError === "Failure") {
      showToast({
        type: "error",
        message: `${t("Something went wrong!")}`,
      });

      dispatch(get_DriverList());
      dispatch(create_one_driver_clear());
    }
  }, [updateStatus, createStatus, createError, updateError]);

  useEffect(() => {
    if (id) dispatch(get_one_driver({ id }));
  }, [id]);
  console.log("driver", driver);
  useEffect(() => {
    if (driver) {
      setActionItem({
        ...driver,
        is_motor_cycle_messager: driver.is_motor_cycle_messager,
        date_of_birth:
          driver.date_of_birth && moment(parseInt(driver.date_of_birth, 10)),
        allotment_date:
          driver.allotment_date && moment(parseInt(driver.allotment_date, 10)),
        date_of_retirement:
          driver.date_of_retirement &&
          moment(parseInt(driver.date_of_retirement, 10)),
        photo: driver?.photo
          ? [
            {
              uid: "-1",
              name: "Photo",
              status: "done",
              url: driver?.photo,
            },
          ]
          : [],
        id_proof: driver?.id_proof
          ? [
            {
              uid: "-1",
              name: "ID Proof",
              status: "done",
              url: driver?.id_proof,
            },
          ]
          : [],
        go_file: driver?.go_file
          ? [
            {
              uid: "-1",
              name: "GO File",
              status: "done",
              url: driver?.go_file,
            },
          ]
          : [],
      });
    }
  }, [driver]);

  const handleInputChange = (value) => {
    if (value.types === "transit_pass") {
      setTransitShow(true);
    } else setTransitShow(false);
    if (value.pass_type === "visitors") {
      setVisitors(true);
    } else setVisitors(false);
    if (value.driver_classification === "political_driver") {
      setPoliticalDriver(true);
    } else setPoliticalDriver(false);
  };
  const [form] = AntdForm.useForm();
  const driver_types = [
    { value: "government_driver", label: "Government Driver" },
    { value: "texco_driver", label: "Texco Driver" },
    { value: "political_driver", label: "Political Driver" },
    { value: "contract_driver", label:"Contract Driver"}
  ];
  const handle_change = (value) => {
    set_is_motor_cycle_messager(value?.target?.checked)
  }
  return (
    <Box flex="1">
      <Box position="sticky" flex={1} mb="10" mt="4">
        <Text fontWeight={"bold"} fontSize="md">
          {id ? "Edit Driver" : "Add Driver"}
        </Text>
      </Box>
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={handleInputChange}
      >
        <VStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.TextBox
                field={"name"}
                label={t("form:name")}
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"mobile"}
                label={t("form:mobile_number")}
                rules={[
                  {
                    required: true,
                    message: "Mobile Number is required",
                  },
                  {
                    pattern: new RegExp("^[6-9][0-9]{9}$"),
                    message: "Enter valid Mobile Number",
                  },
                ]}
              />
            </Box>
          </HStack>

          <HStack space={5}>
            <Box flex={1}>
              <Form.TextBox
                field={"aadhar"}
                label={t("form:aadhar_number")}
                rules={[
                  {
                    required: false,
                    message: "Aadhar Number is required",
                  },
                  {
                    pattern: new RegExp("^[0-9]{12}$"),
                    message: "Enter valid aadhar number",
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.File field={"photo"} label={t("form:photo")} />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.Date
                field={"date_of_birth"}
                label={t("form:date_of_birth")}
                rules={[
                  {
                    required: false,
                    message: "Date of birth is required",
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.Date
                field={"date_of_retirement"}
                label={t("form:date_of_retirement")}
                rules={[
                  {
                    required: false,
                    message: "Date of retirement is required",
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.TextBox
                field={["address", "line1"]}
                label={t("form:address_line1")}
                rules={[
                  {
                    required: false,
                    message: "Address Line 1 is required",
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={["address", "line2"]}
                label={t("form:address_line2")}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.Select
                field={["address", "district_id"]}
                label={t("District")}
                options={districts}
                labelField="name"
                valueField="id"
                rules={[
                  {
                    required: true,
                    message: "District is required",
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={["address", "pincode"]}
                label={t("PIN Code")}
                rules={[
                  {
                    required: false,
                    message: "PIN Code is required",
                  },
                  {
                    pattern: new RegExp("^6[0-9]{5}$"),
                    message: "Enter valid PIN Code",
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.Select
                field={"driver_classification"}
                label={t("Driver Types")}
                options={driver_types}
                labelField="label"
                valueField="value"
                rules={[
                  {
                    required: true,
                    message: "Driver type is required",
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.File
                field={"id_proof"}
                label={t("ID Proof")}
                rules={[
                  {
                    required: is_political_driver,
                    message: "ID Proof is required",
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack flex={1} space={10}>
            <Box flex={1}>
              <Form.Select
                field={"alloted_department_id"}
                label={t("form:allocated_department")}
                options={department}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: "Alloted Department is required",
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"alloted_designation_id"}
                label={t("form:allocated_designation")}
                options={designations}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: "Alloted Designation is required",
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.Date
                field={"allotment_date"}
                label={t("form:allocated_date")}
                rules={[
                  {
                    required: false,
                    message: "Alloted date is required",
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.File
                field={"go_file"}
                label={t("form:go_file")}
                rules={[
                  {
                    required: false,
                    message: "GO file is required",
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <HStack flex={1} space={"5px"}>
              {/* <AntdForm.Item name={"is_motor_cycle_messager"}>
                <Checkbox onChange={handle_change}>{t("form:is_an_motor_cycle_massagers")}</Checkbox>
              </AntdForm.Item> */}
              <Form.CheckBox
                field={"is_motor_cycle_messager"}
                label={t("form:is_an_motor_cycle_massagers")}
              />
            </HStack>
            <Box flex={1}>

            </Box>
          </HStack>
          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              isLoading={id ? updateLoading : createLoading}
              width="100px"
              colorScheme="primary"
              height="45px"
              formErrorMessage={t("error:error_message")}
              label={actionItem.id ? t("update") : t("add")}
            >
              {actionItem.id ? t("update") : t("add")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onClick={() => {
                history.goBack();
              }}
              formErrorMessage={t("error:error_message")}
            >
              {t("common:back")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default DriverDetails;
