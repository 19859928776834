import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  HStack,
  VStack,
  Center,
  Text,
  Pressable,
  SimpleGrid,
  Image,
} from "native-base";
import {
  dashboardSelector,
  dashboard,
  department_dashboard,
  department_dashboardSelector,
  technical_person_dashboard,
  technicalPersonDashboardSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import { retrieveItem, useStorageItem } from "@helpers/storage";
import data from "@assets/images/loader.gif";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Vechicle from "@assets/icons/vehicle.png";
import GuestHouse from "@assets/icons/country-house.png";
import OtherDepartment from "@assets/icons/garage.png";
import Chief from "@assets/icons/man.png";
import Minister from "@assets/icons/businessman.png";

import Inspection from "@assets/icons/inspection.png";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { Button } from "antd";
import { IoMdArrowRoundBack } from "react-icons/io";

const AllocatedDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const roles = JSON.parse(retrieveItem("roles"));
  const { t } = useTranslation();
  const { name } = retrieveItem("user");

  useEffect(() => {
    dispatch(dashboard());
    dispatch(department_dashboard());
    dispatch(technical_person_dashboard());
  }, []);

  const { item, loading } = useSelector(dashboardSelector);
  const { item: department } = useSelector(department_dashboardSelector);
  const { item: technical_person_dashboard_list } = useSelector(
    technicalPersonDashboardSelector
  );

  const handleAllotments = (value) => {
    history.push(`${ROUTES.VEHICLE_LIST}/${value}`);
  };

  const handleDepartmentRedirect = (value, department_id) => {
    history.push(`${ROUTES.VEHICLE_LIST}/${value}/${department_id}`);
  };

  
  const handleGoBack = () => {
    history.goBack();
  };

  if (loading)
    return (
      <Box alignSelf="center" marginTop="25%">
        <img src={data} />
      </Box>
    );
  let currentDate = "";
  currentDate = new Date().toLocaleDateString();

  let allocated_chief_minister_count = 0;
  allocated_chief_minister_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "f842edb1-a105-4560-ba43-1cd626fc70e8"
    )?.count;

  let allocated_minister_count = 0;
  allocated_minister_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "6bdf3a7f-9396-4bb5-8f41-49c792be6b59"
    )?.count;
  let allocated_public_department_count = 0;
  allocated_public_department_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "ab60c9c2-3d4f-4cb0-a8c0-a5ca93a8c458"
    )?.count;

  let allocated_state_guest_count = 0;
  allocated_state_guest_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "89e9a04d-54bd-475f-a985-6a4675615d3d"
    )?.count;

  let allocated_other_department =
    item?.allotted -
    (Number(allocated_chief_minister_count) +
      Number(allocated_minister_count) +
      Number(allocated_public_department_count) +
      Number(allocated_state_guest_count));


  const public_department = () => {
    handleDepartmentRedirect(
      "department",
      "ab60c9c2-3d4f-4cb0-a8c0-a5ca93a8c458"
    );
  };
  const handleChiefMinister = () => {
    handleDepartmentRedirect(
      "department",
      "f842edb1-a105-4560-ba43-1cd626fc70e8"
    );
  };
  const handleMinister = () => {
    handleDepartmentRedirect(
      "department",
      "6bdf3a7f-9396-4bb5-8f41-49c792be6b59"
    );
  };
  const handleGuestHouse = () => {
    handleDepartmentRedirect(
      "department",
      "89e9a04d-54bd-475f-a985-6a4675615d3d"
    );
  };
  let dashboards = [];

  let title = "";
  let title2 = "";
  if (
    roles?.[0]?.name === "Admin" ||
    roles?.[0]?.name === "Public Department" ||
    roles?.[0]?.name === "ASO" ||
    roles?.[0]?.name === "SO" ||
    roles?.[0]?.name === "Deputy Secretary" ||
    roles?.[0]?.name === "Under Secretary"
  ) {
    title = "";
    title2 = "Fuel";
    dashboards.push(
      {
        name: t("chief_minister_office"),
        value: allocated_chief_minister_count || 0,
        color: "#82aebf",
        sub_color: "#658997",
        icon: (
          <Image
            source={Chief}
            size={"50px"}
            style={{ tintColor: "#658997" }}
          />
        ),
        onPress: handleChiefMinister,
      },
      {
        name: t("Hon'ble Minister"),
        value: allocated_minister_count || 0,
        color: "#FFD24C",
        sub_color: "#d1ad3f",
        icon: (
          <Image
            source={Minister}
            size={"50px"}
            style={{ tintColor: "#d1ad3f" }}
          />
        ),
        onPress: handleMinister,
      },
      {
        name: t("public_department"),
        value: allocated_public_department_count || 0,
        color: "#AD8B73",
        sub_color: "#8c6f5b",
        icon: (
          <Image
            source={Vechicle}
            size={"50px"}
            style={{ tintColor: "#8c6f5b" }}
          />
        ),
        onPress: public_department,
      },
      {
        name: "Other Departments",
        value: allocated_other_department || 0,
        color: "#C88EA7",
        sub_color: "#a57f8f",
        icon: (
          <Image
            source={OtherDepartment}
            size={"50px"}
            style={{ tintColor: "#a57f8f" }}
          />
        ),
      },
      {
        name: t("state_guest_house"),
        value: allocated_state_guest_count || 0,
        color: "#069A8E",
        sub_color: "#068278",
        icon: (
          <Image
            source={GuestHouse}
            size={"50px"}
            style={{ tintColor: "#068278" }}
          />
        ),
        onPress: handleGuestHouse,
      }
    );
     
  } else if (roles?.[0]?.name === "Technician") {
    title = "Vehicles";
    dashboards.push(
      {
        name: technical_person_dashboard_list?.[0]?.name,
        value: technical_person_dashboard_list?.[0]?.count || 0,
        // onPress: handleAllotments(true),
        color: "#0c7297",
        sub_color: "#105d78",
        icon: <MdOutlineMiscellaneousServices color="#105d78" size={50} />,
      },
      {
        name: technical_person_dashboard_list?.[1]?.name,
        value: technical_person_dashboard_list?.[1]?.count || 0,
        color: "#C88EA7",
        sub_color: "#ab8495",
        icon: (
          <Image
            source={Inspection}
            size={"50px"}
            style={{ tintColor: "#ab8495" }}
          />
        ),
      }
    );
  }
  return (
    <>
    <Box>
       <Box alignItems={"start"}>
        <Button onClick={handleGoBack}>
          <IoMdArrowRoundBack size={"20px"} />
        </Button>
      </Box>
    </Box>
    <Box width="100%" alignItems="center">
      
      <Box alignItems={"center"} justifyContent={"center"} height={"100%"}>
        <Text fontSize="3xl" bold pb={4} color={"black"}>
          {title}
        </Text>
        <SimpleGrid
          columns={{
            base: 1,
            xs: 1,
            md: 3,
            sm: 2,
            lg: 4,
            xl: 4,
            "2xl": 5,
          }}
          spacingY={"27px"}
          spacingX={21}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {dashboards?.map((option) => {
            return (
              <Box
                size="100px"
                backgroundColor={option.color}
                style={{
                  cursor: "pointer",
                  width: "220px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Pressable onPress={option.onPress}>
                  <VStack
                    justifyContent={"space-between"}
                    alignItems={"space-around"}
                  >
                    <HStack
                      space={"10px"}
                      justifyContent={"space-around"}
                      // padding={"10px"}
                    >
                      <Box h={"80px"} justifyContent={"center"}>
                        {option.icon}
                      </Box>
                      <Box h={"80px"} justifyContent={"center"}>
                        <Text
                          color={"white"}
                          fontSize={"26px"}
                          // fontStyle={"italic"}
                          fontWeight={600}
                        >
                          {option?.value}
                        </Text>
                      </Box>
                    </HStack>
                    <Box
                      backgroundColor={option?.sub_color}
                      justifyContent={"center"}
                      width={"100%"}
                      height={"30px"}
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <Text
                        textAlign={"center"}
                        alignItems={"center"}
                        color={"white"}
                        fontSize={"14px"}
                        fontWeight={500}
                      >
                        {option?.name}
                      </Text>
                    </Box>
                  </VStack>
                </Pressable>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
    </Box>
    </>
  );
};
export default AllocatedDashboard;
