import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Upload, Row, Col, Card } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Box, HStack, Text, VStack } from "native-base";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "@views/routes/my_routes";
import { PDFDownloadLink } from "@react-pdf/renderer";
import GeneratePass from "../generate_pass/generate_pass";
import GenerateVehiclePass from "../generate_pass/generate_vehicle_pass";
import {
  get_Departments,
  get_designation,
  get_one_gatepass,
  get_pass_typeList,
  getDepartmentListSelector,
  getDesignationListSelector,
  getOnegatepassSelector,
  getpass_typeListSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { retrieveItem } from "@helpers/storage";
import { startCase } from "lodash";
import GenerateTransitPass from "../generate_pass/generate_transit_pass";
import moment from "moment";
import { t } from "i18next";
import GenerateTwoWheelerPass from "../generate_pass/generate_two_wheeler";

const { TextArea } = Input;
const { Option } = Select;

const PassHistoryView = () => {
  const dispatch = useDispatch();
  const { id, type } = useParams();
  console.log("typetype", type);

  const history = useHistory();

  const { item: pass_item } = useSelector(getOnegatepassSelector);
  console.log("pass_item", pass_item);

  const issued_date = pass_item?.issued_date
    ? moment(Number(pass_item?.issued_date)).format("DD/MM/YYYY")
    : "";

  const valid_till = pass_item?.valid_till
    ? moment(Number(pass_item?.valid_till)).format("DD/MM/YYYY")
    : "";

  let departments = [
    {
      label: "Chief Minister's Office",
      value: "chief_minister_officer",
    },
    {
      label: "Public Department",
      value: "public_department",
    },
  ];

  useEffect(() => {
    dispatch(get_one_gatepass({ id: id }));
  }, [id]);

  return (
    <Box flex="1" p="20px">
      <HStack justifyContent={"space-around"} alignItems={"center"}>
        <Box position="sticky" flex={1} mb="10" mt="4">
          <Text fontWeight={"bold"} fontSize="md">
            {t("pass_history_details")}
          </Text>
        </Box>
        <HStack space={"10px"}>
          {pass_item?.status === "active" &&
            pass_item?.entity_state?.entity?.name ===
              "Secretariat Staff / Public Pass " &&
            pass_item?.pass_type?.name === "Parking" &&
            pass_item?.pass_type?.parent_pass_type?.name ===
              "Four Wheeler Pass" && (
                <PDFDownloadLink
                  fileName="Generate_pass.pdf"
                  document={<GeneratePass pass_item={pass_item} />} // parking pass
                >
                  <Button>{"Download Pass"}</Button>
                </PDFDownloadLink>
            )}
          {pass_item?.status === "active" &&
            pass_item?.pass_type?.name === "Transit" &&
            (pass_item?.entity_state?.entity?.name === "VIP/ HOD Pass" ||
              "Secretariat Staff / Public Pass ") && (
              <>
                <PDFDownloadLink
                  fileName="Generate transit pass.pdf"
                  document={<GenerateTransitPass pass_item={pass_item} />} // transit pass
                >
                  <Button>{"Download Pass"}</Button>
                </PDFDownloadLink>
              </>
            )}
          {pass_item?.status === "active" &&
            pass_item?.entity_state?.entity?.name === "VIP/ HOD Pass" &&
            pass_item?.pass_type?.name === "Parking" &&  pass_item?.pass_type?.parent_pass_type?.name ===
            "Four Wheeler Pass" && (
              <PDFDownloadLink
                fileName="Generate_pass.pdf"
                document={<GenerateVehiclePass pass_item={pass_item} />} // vip pass
              >
                <Button>{"Download Pass"}</Button>
              </PDFDownloadLink>
            )}
          {pass_item?.status === "active" &&
            (pass_item?.entity_state?.entity?.name ===
              "Secretariat Staff / Public Pass " || "VIP/ HOD Pass") &&
            pass_item?.pass_type?.name === "Parking" &&
            pass_item?.pass_type?.parent_pass_type?.name ===
              "Two Wheeler Pass" && (
              <PDFDownloadLink
                fileName="Generate_pass.pdf"
                document={<GenerateTwoWheelerPass pass_item={pass_item} />} // two wheeler pass
              >
                <Button>{"Download Pass"}</Button>
              </PDFDownloadLink>
            )}
        </HStack>
      </HStack>

      <Box>
        <Card mb="5">
          <VStack space="5">
            <HStack justifyContent={"flex-end"} space="4" right="6">
              <Box>
                <Text fontSize={"16px"} bold>
                  {t("status")}:
                </Text>
              </Box>
              <Box>
                {pass_item?.status === "active" ? (
                  <Text fontSize={"16px"} color={"green.800"}>
                    {startCase(pass_item?.status)}
                  </Text>
                ) : (
                  <Text fontSize={"16px"}>{startCase(pass_item?.status)}</Text>
                )}
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("name")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{pass_item?.name} </Text>
                  </Box>
                </HStack>
              </Box>

              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("pass_number")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text bold>
                      {pass_item?.pass_number
                        ? pass_item?.pass_number
                        : "Pass Number is Not Allocated"}{" "}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                {/* {type === "secretariat_staff" && ( */}
                {pass_item?.designation?.name && (
                  <HStack>
                    <Box width="35%">
                      <Text bold>{t("designation")}:</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>{pass_item?.designation?.name}</Text>
                    </Box>
                  </HStack>
                )}
                {/* )} */}
              </Box>

              <Box flex={1}>
                {/* {(type === "secretariat_staff" || type === "hod") && ( */}
                {pass_item?.department?.name && (
                  <HStack>
                    <Box width="35%">
                      <Text bold>{t("department")}:</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>{pass_item?.department?.name}</Text>
                    </Box>
                  </HStack>
                )}
                {/* )} */}
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("mobile")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{pass_item?.created_by?.mobile} </Text>
                      </Box>
                    </HStack>
                  </Box>

                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("pass")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {pass_item?.pass_type?.parent_pass_type?.name}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("pass_type")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{pass_item?.pass_type?.name}</Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("vehicle_registration_number")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{pass_item?.vehicle_number} </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("make_model")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{pass_item?.vehicle_make}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("ownership_details")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{startCase(pass_item?.ownership_detail)}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>

            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("issued_date")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{issued_date ? issued_date : "-"}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("valid_till")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{valid_till ? valid_till : "-"}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>

            {/* {gate_pass?.pass_type?.name !== "Visitors" && ( */}
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("purpose_of_visit")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{pass_item?.purpose_of_visit}</Text>
                      </Box>
                    </HStack>
                  </Box>

                  <Box flex={1}>
                    {pass_item?.rc_book_url === null ? (
                      " "
                    ) : (
                      <HStack>
                        <Box width="35%">
                          <Text bold>{t("rc_book")}:</Text>
                        </Box>
                        <Box flex={1}>
                          {pass_item?.rc_book_url && (
                            <Text fontSize="md">
                              <a
                                href={pass_item?.rc_book_url}
                                target={"_blank"}
                                style={{
                                  textDecoration: "underline",
                                }}
                              >
                                Rc Book
                              </a>
                            </Text>
                          )}
                        </Box>
                      </HStack>
                    )}
                  </Box>
                </HStack>
              </Box>
            </HStack>
            {/* )} */}

            <HStack>
              <Box flex={1}>
                {pass_item?.recommendation_letter_url === null ? (
                  " "
                ) : (
                  <HStack>
                    <Box width="35%">
                      <Text bold>{t("recommendation_letter")}:</Text>
                    </Box>
                    <Box flex={1}>
                      {pass_item?.recommendation_letter_url && (
                        <Text fontSize="md">
                          <a
                            href={pass_item?.recommendation_letter_url}
                            target={"_blank"}
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Recommendation letter
                          </a>
                        </Text>
                      )}
                    </Box>
                  </HStack>
                )}
              </Box>

              <Box flex={1}>
                {pass_item?.remarks === null ? (
                  ""
                ) : (
                  <HStack>
                    <Box width="35%">
                      <Text bold>{t("remark")}:</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>{pass_item?.remarks}</Text>
                    </Box>
                  </HStack>
                )}
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("address")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{pass_item?.address}</Text>
                  </Box>
                </HStack>
              </Box>

              <Box flex={1}></Box>
            </HStack>
          </VStack>
        </Card>
      </Box>

      <HStack space={"5px"} justifyContent={"flex-end"} my={"10px"}>
        <Button
          onClick={() => {
            history.goBack();
          }}
          type="default"
          danger
        >
          {t("back")}
        </Button>
      </HStack>
    </Box>
  );
};

export default PassHistoryView;
