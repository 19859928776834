import React, { useEffect, useState } from "react";
import { Box, Card, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import {
  get_GatePassList,
  get_one_gatepass,
  get_pass_entity_state,
  getOnegatepassSelector,
  getPassEntityStateSelector,
  update_gate_pass,
  update_one_gate_pass_clear,
  updateGatePassSelector,
} from "@services/redux";
import { Button, Input, Modal } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { retrieveItem } from "@helpers/storage";
import { showToast } from "@helpers/toast";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import GeneratePass from "../generate_pass/generate_pass";
import GenerateTransitPass from "../generate_pass/generate_transit_pass";
import GenerateVehiclePass from "../generate_pass/generate_vehicle_pass";
import GenerateTwoWheelerPass from "../generate_pass/generate_two_wheeler";

const GatePassView = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();
  const [dialog_visible, setDialogVisible] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [buttonType, setButtonType] = useState("");

  const { TextArea } = Input;
  const { item: gate_pass } = useSelector(getOnegatepassSelector);

  // const select = useSelector(state => state?.getPassEntityState)
  // console.log("select", select);

  const { item: pass_entity_item } = useSelector(getPassEntityStateSelector);
  const {
    loading: updateLoading,
    error: updateError,
    status: updateStatus,
  } = useSelector(updateGatePassSelector);

  useEffect(() => {
    dispatch(get_one_gatepass({ id: id }));
    dispatch(get_pass_entity_state({ pass_id: id }));
  }, [id]);

  const formatStatus = (status) => {
    console.log("status", status);

    switch (status) {
      case "active":
        return "Active";
      case "expired":
        return "Expired";
      case "submitted":
        return "Submitted";
    }
  };

  const roles = JSON.parse(retrieveItem("roles"));

  const issued_date = gate_pass?.issued_date
    ? moment(Number(gate_pass?.issued_date)).format("DD/MM/YYYY")
    : "";

  const valid_till = gate_pass?.valid_till
    ? moment(Number(gate_pass?.valid_till)).format("DD/MM/YYYY")
    : "";

  const handleApprove = () => {
    setButtonType("approve");
    const entity_state_transition_id = pass_entity_item?.items?.[0]?.id;
    dispatch(
      update_gate_pass({
        id: gate_pass?.id,
        data: { entity_state_transition_id },
      })
    );
  };

  const handleReject = () => {
    setDialogVisible(true);
  };

  const handleRejectButton = () => {
    setButtonType("reject");
    const entity_state_transition_id = pass_entity_item?.items?.[1]?.id;
    dispatch(
      update_gate_pass({
        id: gate_pass?.id,
        data: { entity_state_transition_id, remarks: remarks },
      })
    );
  };

  const handleModalClose = () => {
    setDialogVisible(false);
  };

  useEffect(() => {
    if (updateStatus === "Success") {
      {
        buttonType === "approve" &&
          showToast({
            type: "success",
            message:
              pass_entity_item?.items?.[0]?.transition_name === "Forward to SO"
                ? "Forwarded to SO"
                : pass_entity_item?.items?.[0]?.transition_name ===
                  "Forward to US"
                ? "Forwarded to US"
                : pass_entity_item?.items?.[0]?.transition_name ===
                  "Forward to DS"
                ? "Forwarded to DS"
                : pass_entity_item?.items?.[0]?.transition_name === "Issue Pass"
                ? "Issued Pass"
                : "",
          });
      }
      {
        buttonType === "reject" &&
          showToast({
            type: "success",
            message: `${pass_entity_item?.items?.[1]?.transition_name} ${t(
              "update_successfully"
            )}`,
          });
      }
      history.goBack();
      dispatch(update_one_gate_pass_clear());
      dispatch(get_GatePassList());
    } else if (updateError === "Failure") {
      showToast({
        type: "error",
        message: updateError?.message,
      });
      dispatch(update_one_gate_pass_clear());
    }
  }, [updateStatus, updateError]);

  return (
    <Box flex="1">
      <HStack justifyContent={"space-around"} alignItems={"center"}>
        <Box position="sticky" flex={1} mb="10" mt="4">
          <Text fontWeight={"bold"} fontSize="md">
            {t("vehicle_pass_details")}
          </Text>
        </Box>
        <HStack space={"10px"}>
          {gate_pass?.status === "active" &&
            gate_pass?.entity_state?.entity?.name ===
              "Secretariat Staff / Public Pass " &&
            gate_pass?.pass_type?.name === "Parking" &&
            gate_pass?.pass_type?.parent_pass_type?.name ===
              "Four Wheeler Pass" && (
              <PDFDownloadLink
                fileName="Generate_pass.pdf"
                document={<GeneratePass pass_item={gate_pass} />} // parking pass
              >
                <Button>{"Download Pass"}</Button>
              </PDFDownloadLink>
            )}
          {gate_pass?.status === "active" &&
            gate_pass?.pass_type?.name === "Transit" &&
            (gate_pass?.entity_state?.entity?.name === "VIP/ HOD Pass" ||
              "Secretariat Staff / Public Pass ") && (
              <>
                <PDFDownloadLink
                  fileName="Generate transit pass.pdf"
                  document={<GenerateTransitPass pass_item={gate_pass} />} // transit pass
                >
                  <Button>{"Download Pass"}</Button>
                </PDFDownloadLink>
              </>
            )}
          {gate_pass?.status === "active" &&
            gate_pass?.entity_state?.entity?.name === "VIP/ HOD Pass" &&
            gate_pass?.pass_type?.name === "Parking" &&
            gate_pass?.pass_type?.parent_pass_type?.name ===
              "Four Wheeler Pass" && (
              <PDFDownloadLink
                fileName="Generate_pass.pdf"
                document={<GenerateVehiclePass pass_item={gate_pass} />} // vip pass
              >
                <Button>{"Download Pass"}</Button>
              </PDFDownloadLink>
            )}
          {gate_pass?.status === "active" &&
            (gate_pass?.entity_state?.entity?.name ===
              "Secretariat Staff / Public Pass " ||
              "VIP/ HOD Pass") &&
            gate_pass?.pass_type?.name === "Parking" &&
            gate_pass?.pass_type?.parent_pass_type?.name ===
              "Two Wheeler Pass" && (
              <PDFDownloadLink
                fileName="Generate_pass.pdf"
                document={<GenerateTwoWheelerPass pass_item={gate_pass} />} // two wheeler pass
              >
                <Button>{"Download Pass"}</Button>
              </PDFDownloadLink>
            )}
        </HStack>
      </HStack>
      <Box>
        <Card mb="5">
          <VStack space="5">
            <HStack justifyContent={"flex-end"} space="4" right="6">
              <Box>
                <Text fontSize={"16px"} bold>
                  {t("status")}:
                </Text>
              </Box>
              <Box>
                {gate_pass?.status === "active" ? (
                  <Text fontSize={"16px"} color={"green.800"}>
                    {formatStatus(gate_pass?.status)}
                  </Text>
                ) : (
                  <Text fontSize={"16px"}>
                    {formatStatus(gate_pass?.status)}
                  </Text>
                )}
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("name")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{gate_pass?.name} </Text>
                  </Box>
                </HStack>
              </Box>

              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("pass_number")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text bold>
                      {gate_pass?.pass_number
                        ? gate_pass?.pass_number
                        : "Pass Number is Not Allocated"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              {gate_pass?.designation === null ? (
                " "
              ) : (
                <Box flex={1}>
                  <HStack>
                    <Box width="35%">
                      <Text bold>{t("designation")}:</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>{gate_pass?.designation?.name}</Text>
                    </Box>
                  </HStack>
                </Box>
              )}
              {gate_pass?.department === null ? (
                ""
              ) : (
                <Box flex={1}>
                  <HStack>
                    <Box width="35%">
                      <Text bold>{t("department")}:</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>{gate_pass?.department?.name}</Text>
                    </Box>
                  </HStack>
                </Box>
              )}
              {gate_pass?.department && gate_pass?.designation ? (
                " "
              ) : (
                <Box flex={1}></Box>
              )}
            </HStack>

            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("pass")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {gate_pass?.pass_type?.parent_pass_type?.name}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("pass_type")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{gate_pass?.pass_type?.name}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("vehicle_registration_number")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{gate_pass?.vehicle_number} </Text>
                  </Box>
                </HStack>
              </Box>

              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("make_model")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{gate_pass?.vehicle_make}</Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("ownership_details")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{gate_pass?.ownership_detail}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("purpose_of_visit")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{gate_pass?.purpose_of_visit}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>

            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("issued_date")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{issued_date ? issued_date : "-"}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>{t("valid_till")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{valid_till ? valid_till : "-"}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>

            {/* {gate_pass?.pass_type?.name !== "Visitors" && ( */}
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    {gate_pass?.rc_book_url && (
                      <HStack>
                        <Box width="35%">
                          <Text bold>{t("rc_book")}:</Text>
                        </Box>
                        <Box flex={1}>
                          {gate_pass?.rc_book_url && (
                            <Text fontSize="md">
                              <a
                                href={gate_pass?.rc_book_url}
                                target={"_blank"}
                                style={{
                                  textDecoration: "underline",
                                }}
                              >
                                Rc Book
                              </a>
                            </Text>
                          )}
                        </Box>
                      </HStack>
                    )}
                  </Box>
                  <Box flex={1}>
                    {gate_pass?.recommendation_letter_url && (
                      <HStack>
                        <Box width="35%">
                          <Text bold>{t("recommendation_letter")}:</Text>
                        </Box>
                        <Box flex={1}>
                          {gate_pass?.recommendation_letter_url && (
                            <Text fontSize="md">
                              <a
                                href={gate_pass?.recommendation_letter_url}
                                target={"_blank"}
                                style={{
                                  textDecoration: "underline",
                                }}
                              >
                                Recommendation letter
                              </a>
                            </Text>
                          )}
                        </Box>
                      </HStack>
                    )}
                  </Box>
                </HStack>
              </Box>
            </HStack>
            {/* )} */}

            <HStack space={"5px"}>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("mobile")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{gate_pass?.created_by?.mobile} </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>{t("address")}:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{gate_pass?.address}</Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                {gate_pass?.remarks === null ? (
                  ""
                ) : (
                  <HStack>
                    <Box width="35%">
                      <Text bold>{t("remark")}:</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>{gate_pass?.remarks}</Text>
                    </Box>
                  </HStack>
                )}
              </Box>
              <Box flex={1}></Box>
            </HStack>
          </VStack>
        </Card>
      </Box>

      <HStack space={"5px"} justifyContent={"flex-end"}>
        {pass_entity_item?.items?.length === 0 ? (
          " "
        ) : (
          <>
            {(roles?.[0]?.name === "Under Secretary" ||
              roles?.[0]?.name === "Deputy Secretary" ||
              roles?.[0]?.name === "ASO" ||
              roles?.[0]?.name === "SO") && (
              <>
                <Button
                  onClick={handleApprove}
                  loading={buttonType === "approve" && updateLoading}
                  width="100px"
                  colorScheme="#2fb345"
                  variant="outline"
                  style={{
                    backgroundColor: "#1d6758",
                    color: "white",
                  }}
                >
                  {pass_entity_item?.items?.[0]?.transition_name}{" "}
                  {/* Forward */}
                </Button>
                <Button
                  onClick={handleReject}
                  width="100px"
                  colorScheme="#2fb345"
                  variant="outline"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                  }}
                >
                  {pass_entity_item?.items?.[1]?.transition_name} {/* Reject */}
                </Button>
              </>
            )}
          </>
        )}
        <Button
          onClick={() => {
            history.goBack();
          }}
          type="default"
          danger
        >
          {t("back")}
        </Button>
      </HStack>

      <Box mt="10px" width="200px" zIndex={1}></Box>

      <Modal
        open={dialog_visible}
        title={t("are_you_sure_to_reject")}
        onCancel={handleModalClose}
        footer={[
          <Button key="cancel" onClick={handleModalClose}>
            {t("cancel")}
          </Button>,
          <Button
            loading={buttonType === "reject" && updateLoading}
            key="save"
            type="default"
            style={{
              backgroundColor: "red",
              color: "white",
            }}
            onClick={handleRejectButton}
          >
            {t("reject")}
          </Button>,
        ]}
      >
        <Box my={5}>
          <TextArea
            placeholder="Enter remarks..."
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Box>
      </Modal>
    </Box>
  );
};
export default GatePassView;
