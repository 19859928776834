import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  HStack,
  VStack,
  Text,
  FormControl,
  Modal as NativeModal,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import data from "@assets/images/loader.gif";

import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";

import { Timeline, TimelineEvent } from "react-event-timeline";
import { AiFillCar } from "react-icons/ai";

import {
  getTyre_Makers,
  create_allotment_reset,
  getAll_Agency,
  getAgencyListSelector,
  getAll_Accessories,
  getAccessoriesListSelector,
  create_Allotments,
  getCreateAllotmentsListSelector,
  getone_Vehicles,
  getOneVehiclesListSelector,
  get_Departments,
  getDesignationListSelector,
  get_designation,
  getDriverListSelector,
  get_DriverList,
  getMakerListSelector,
  getDepartmentListSelector,
  getCar_Makers,
} from "@services/redux";
import ReactSelect from "react-select";
import ActionButton from "../../components/ui/table/action_button";

import unicConvertes from "../../../helpers/date";

import Modal from "@views/components/ui/modal";

import ExportDetails from "./vehicle_return_details";
import { Button, Card, Form as AntdForm } from "antd";
import { retrieveItem } from "@helpers/storage";

const AllotmentView = (props) => {
  const dispatch = useDispatch();
  const roles = JSON.parse(retrieveItem("roles"));
  const [form] = AntdForm.useForm();

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [makerChoose, setMakerChoose] = useState("");
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [currentMakerModels, setCurrentMakerModels] = useState("");
  const [model, setModel] = useState(false);
  const [returnDialogVisible, setReturnDialogVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const { item: vehicle_get_one } = useSelector(getOneVehiclesListSelector);
  const { items: maker_items } = useSelector(getMakerListSelector);
  const { items: accessories_items } = useSelector(getAccessoriesListSelector);
  const { items: designations_items } = useSelector(getDesignationListSelector);

  const { items: department_items } = useSelector(getDepartmentListSelector);

  const { createStatus, loading } = useSelector(
    getCreateAllotmentsListSelector
  );
  const { items: driver } = useSelector(getDriverListSelector);
  useEffect(() => {
    dispatch(getCar_Makers({}));
    dispatch(getTyre_Makers({}));
    dispatch(getAll_Agency({}));
    dispatch(getAll_Accessories({}));
    dispatch(get_Departments({}));
    dispatch(get_designation({}));
    dispatch(get_DriverList());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getone_Vehicles({ id: id }));
    }
  }, [id]);
  // useEffect(() => {
  //   let vehicle = {};
  //   if (vehicle_get_one?.id) {
  //     vehicle = {
  //       ...vehicle_get_one,
  //     };
  //     setActionItem(vehicle);
  //   } else {
  //     form.resetFields();
  //     setActionItem({
  //       allotment_copy: [
  //         {
  //           uid: "-1",
  //           name: "Photo",
  //           status: "done",
  //           url: "",
  //         },
  //       ],
  //     });
  //   }
  // }, [vehicle_get_one]);
  const handleBack = () => {
    history.push(ROUTES.VEHICLE_LIST);
  };

  useEffect(() => {}, [vehicle_get_one]);

  return (
    <Fragment>
      <Box flex="1" w="100%">
        <Box top="10px" zIndex="2">
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("table:vechicle_allotment")}
          </Text>
        </Box>

        {vehicle_get_one?.allotments?.length > 0 ? (
          <HStack>
            <Box flex={1} borderColor="grey">
              <VStack space="6">
                {vehicle_get_one?.allotments.map(
                  (item, index) => (
                    (
                      <Card>
                        <VStack space={4} mt={7} flex={1}>
                          <VStack flex={1}>
                            <HStack flex={1} space={10}>
                              <HStack flex={1} space={2}>
                                {item?.department ? (
                                  <Box>
                                    <Text fontSize="md" fontWeight="bold">
                                      {t("allotment_go_date")} :
                                    </Text>
                                  </Box>
                                ) : (
                                  <Box>
                                    <Text fontSize="md" fontWeight="bold">
                                      {t("return_date")} :
                                    </Text>
                                  </Box>
                                )}
                                <HStack>
                                  <Box>
                                    <Text fontSize="md">
                                      {unicConvertes(item?.allotment_date_time)}
                                    </Text>
                                  </Box>
                                </HStack>
                              </HStack>
                              <HStack flex={1} space={2}>
                                <Box>
                                  <Text fontSize="md" fontWeight="bold">
                                    {t("allotment_go_number")} :
                                  </Text>
                                </Box>
                                <Box>
                                  <Text fontSize="md">
                                    {item?.allotment_number}
                                  </Text>
                                </Box>
                              </HStack>
                            </HStack>
                          </VStack>

                          <VStack flex={1} space={10}>
                            <HStack flex={1} space={10}>
                              <HStack
                                flex={1}
                                space={2}
                              >
                                <Box>
                                  <Text fontSize="md" fontWeight="bold">
                                    {t("allotted_to")} :
                                  </Text>
                                </Box>
                                <Box>
                                  <Text fontSize="md">
                                    {item?.department?.name}
                                  </Text>
                                </Box>
                              </HStack>
                              <HStack flex={1} space={1}>
                                <Box>
                                  <Text fontSize="md" fontWeight="bold">
                                    {t("designation")} :
                                  </Text>
                                </Box>
                                <Box>
                                  <Text fontSize="md">
                                    {item?.designation?.name}
                                  </Text>
                                </Box>
                              </HStack>
                            </HStack>
                          </VStack>
                          <VStack flex={1} space={10}>
                            <HStack flex={1} space={10}>
                              <HStack flex={1} space={2}>
                                <Box>
                                  <Text fontSize="md" fontWeight="bold">
                                    {t("driver_number")} :
                                  </Text>
                                </Box>
                                <Box>
                                  <Text fontSize="md">
                                    {item?.driver?.mobile}
                                  </Text>
                                </Box>
                              </HStack>
                              <HStack flex={1} space={2}>
                                <Box>
                                  <Text fontSize="md" fontWeight="bold">
                                    {t("driver_name")} :
                                  </Text>
                                </Box>
                                <Box>
                                  <Text fontSize="md">
                                    {item?.driver?.name}
                                  </Text>
                                </Box>
                              </HStack>
                            </HStack>
                          </VStack>
                          <VStack flex={1} space={10}>
                            <HStack flex={1} space={10}>
                              <HStack flex={1} space={2}>
                                <Box>
                                  <Text fontSize="md" fontWeight="bold">
                                    {t("meter_reading")} :
                                  </Text>
                                </Box>
                                <Box>
                                  <Text fontSize="md">
                                    {item?.meter_reading}
                                  </Text>
                                </Box>
                              </HStack>
                              <HStack flex={1} space={2}>
                                <Box>
                                  <Text fontSize="md" fontWeight="bold">
                                    {t("allotment_copy")} :
                                  </Text>
                                </Box>
                                <Box>
                                  <Text>
                                    <a
                                      href={item?.allotment_copy}
                                      target={"_blank"}
                                      style={{
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {item?.allotment_copy === null
                                        ? ""
                                        : "Allotment Copy"}
                                    </a>
                                  </Text>
                                </Box>
                              </HStack>
                            </HStack>
                            {/* <VStack space={5}>
                          <Text fontSize="md" fontWeight="bold">
                            {t("Additional Accessories :")}
                          </Text>

                          {item?.additional_accessory_allotments.map((a1) => {
                            return (
                              <Box>
                                <Text fontSize="md">
                                  {a1?.additional_accessory?.name}
                                </Text>
                              </Box>
                            );
                          })}
                        </VStack> */}
                          </VStack>
                        </VStack>
                      </Card>
                    )
                  )
                )}

                <HStack justifyContent={"flex-end"}>
                  <Box>
                    <Button
                      isLoading={false}
                      width="100px"
                      colorScheme="danger"
                      variant="outline"
                      onClick={handleBack}
                      formErrorMessage={t("error:error_message")}
                    >
                      {t("Back")}
                    </Button>
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </HStack>
        ) : (
          <Text bold textAlign={"center"} mt="8">
            {"No allotments are available for this vehicle."}
          </Text>
        )}
      </Box>
    </Fragment>
  );
};
export default AllotmentView;
