import { gql } from "@apollo/client";

export const dashboard_query = gql`
  query getDashboardCount {
    getDashboardCount {
      total_vehicles
      allotted
      not_allotted
      on_service
      condemned
      department_on_service
      error {
        status_code
        message
      }
    }
  }
`;

export const dashboard_fuel_query = gql`
  query fuelDashboardDetails {
    fuelDashboardDetails {
      this_week_amount
      this_week_quantity
      this_month_quantity
      this_month_amount
      previous_month__quantity
      previous_month_amount
    }
  }
`;
export const department_dashboard_query = gql`
  query departmentWiseVehicleCount {
    departmentWiseVehicleCount {
      items {
        department_id
        name
        count
        error {
          status_code
          message
        }
      }
    }
  }
`;
export const technical_person_dashboard_query = gql`
  query {
    serviceRequestDashboard {
      items {
        name
        count
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const driver_dashboard_count_query = gql`
  query driverDashboardCount {
    driverDashboard {
      total_active_drivers
      not_alloted_drivers
      alloted_drivers
      retired_drivers
    }
  }
`;

export const pass_year_list_query = gql`
  query passYearList {
    passYearList {
      years
    }
  }
`;

export const pass_dashboard_query = gql`
  query passDashboardCount($year: String) {
    passDashboardCount(year: $year) {
      four_wheeler_transit_pass
      four_wheeler_parking_pass
      two_wheeler_parking_pass
      total_pass_count
    }
  }
`;

export const department_driver_dashboard_query = gql`
  query departmentWiseDriverCount {
    departmentWiseDriverCount {
      items {
        department_id
        name
        count
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const department_driver_classification_query=gql`
query departmentWiseDriverClassificationCount($department_id:String!){
  departmentWiseDriverClassificationCount(department_id:$department_id){
    texco_driver_count
    political_driver_count
    regular_driver_count
    contract_driver_count
    other_driver_count
  }
}`
