import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Image,
  VStack,
  HStack,
  Flex,
  Center,
  Hidden,
} from "native-base";
import TamilNaduLogo from "@assets/images/tamilnadu_logo-removebg.png";
import PassLogo from "@assets/images/SECRETARIAT--TRANSIT CAR PASS--2023--KIND-I-RED.jpg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Greentick from "@assets/images/Green-Tick-PNG-Photos.png";
import QRCode from "qrcode";
import { get_one_gatepass, getOnegatepassSelector } from "@services/redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const GenerateVehiclePassView = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [qrCodeData, setQrCodeData] = useState("");

  const { item: passItem } = useSelector(getOnegatepassSelector);
  const validTill = moment(Number(passItem?.valid_till)).format("DD/MM/YYYY");

  useEffect(() => {
    dispatch(get_one_gatepass({ id }));
  }, [id]);

  useEffect(() => {
    if (passItem?.id) {
      const passUrl = `${window.location.origin}/generate-vehicle-pass-view/${passItem.id}`;
      QRCode.toDataURL(passUrl, { width: 100 }, (err, url) => {
        if (!err) {
          setQrCodeData(url);
        } else {
          console.error("QR code generation error:", err);
        }
      });
    }
  }, [passItem?.id]);

  return (
    <>
      <Box flex={1} backgroundColor="white" p={5}>
        {/* Header */}
        <Center mt={20}>
          <VStack space={3} alignItems="center">
            <Image
              source={{ uri: TamilNaduLogo }}
              alt="Tamil Nadu Logo"
              size="80px"
              resizeMode="contain"
            />
            {passItem?.pass_number && (
              <HStack>
                <Text fontSize="lg" bold>
                  VP NO.
                </Text>
                <Text fontSize="md" underline>
                  {passItem?.pass_number || "-"}
                </Text>
              </HStack>
            )}
          </VStack>
        </Center>

        {/* Pass Logo */}
        {/* <Center> */}
        {/* <Image
          source={{ uri: PassLogo }}
          alt="Pass Logo"
          size="620px"
          // size={"500"}
          resizeMode="cover"
          style={{
            position: "absolute",
            alignItems:"center",
            // opacity: 0.5,
            zIndex: -1,
            left:"400px"
          }}
        /> */}
        {/* </Center> */}

        {/* Details Section */}
        <VStack space={4} mt={5}>
          {passItem?.designation?.name && (
            <DetailItem
              label="DESIGNATION"
              value={passItem?.designation?.name || "-"}
            />
          )}
          {passItem?.department?.name && (
            <DetailItem
              label="DEPARTMENT"
              value={passItem?.department?.name || "-"}
            />
          )}
          {passItem?.vehicle_number && (
            <DetailItem
              label="VEHICLE NO"
              value={passItem?.vehicle_number || "-"}
            />
          )}
          {passItem?.vehicle_make && (
            <DetailItem
              label="VEHICLE MAKE"
              value={passItem?.vehicle_make || "-"}
            />
          )}
          {validTill && (
            <DetailItem label="VALIDITY UPTO" value={validTill || "-"} />
          )}
        </VStack>

        {/* QR Code Section */}
        {/* <HStack justifyContent="center" alignItems="center" mt={10} space={"15px"}>
        {qrCodeData && (
          <Image
            source={{ uri: qrCodeData }}
            alt="QR Code"
            size="sm"
            resizeMode="contain"
          />
        )}
        <VStack ml={5}>
          <Text fontSize="sm" bold >
            Dy.Secy / Under Secy to Govt
          </Text>
          <Text fontSize="sm" bold >
            Public (MV) Department
          </Text>
          <Text fontSize="sm" bold >
            Secretariat, Chennai-9
          </Text>
          <Image
            source={{ uri: Greentick }}
            alt="Green Tick"
            size="md"
            style={{
              position: "absolute",
              left: 60,
              opacity: 0.5,
            }}
            resizeMode="contain"
          />
        </VStack>
      </HStack> */}
      </Box>
    </>
  );
};

// Helper component for displaying label-value pairs
const DetailItem = ({ label, value }) => (
  <Flex direction="row" justify="center" space="10px" width="100%">
    <Text fontSize="md" bold>
      {label}:
    </Text>
    <Text fontSize="md" underline>
      {value}
    </Text>
  </Flex>
);

export default GenerateVehiclePassView;
