import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  HStack,
  VStack,
  Text,
  Pressable,
  SimpleGrid,
  Image,
} from "native-base";
import {
  department_driver_dashboard_Selector,
  department_driver_dashboard,
  driver_dashboard,
  driver_dashboard_count_Selector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import { retrieveItem, useStorageItem } from "@helpers/storage";
import data from "@assets/images/loader.gif";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Vechicle from "@assets/icons/vehicle.png";
import GuestHouse from "@assets/icons/country-house.png";
import OtherDepartment from "@assets/icons/garage.png";
import Chief from "@assets/icons/man.png";
import Cm_cell from "@assets/icons/cm cell.png";

import Inspection from "@assets/icons/inspection.png";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { Button } from "antd";
import { IoMdArrowRoundBack } from "react-icons/io";

const AllocatedDriverDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const roles = JSON.parse(retrieveItem("roles"));
  const { t } = useTranslation();
  const { name } = retrieveItem("user");

  const { item: driver_count } = useSelector(driver_dashboard_count_Selector);
  const { item: department } = useSelector(
    department_driver_dashboard_Selector
  );

  useEffect(() => {
    dispatch(driver_dashboard());
    dispatch(department_driver_dashboard());
  }, []);

  const handleDepartmentRedirect = (id) => {
    history.push(`${ROUTES.ALLOCATED_DRIVERS_DASHBOARD}/${id}`);
  };

  const handleGoBack = () => {
    history.push(ROUTES.DASHBOARD);
  };
  let currentDate = "";
  currentDate = new Date().toLocaleDateString();

  let allocated_chief_minister_count = 0;
  allocated_chief_minister_count =
    department?.items?.length &&
    department?.items?.find(
      (x) => x.department_id === "f842edb1-a105-4560-ba43-1cd626fc70e8"
    )?.count;

  let chief_minister_cell_count = 0;
  chief_minister_cell_count =
    department?.items?.length &&
    department?.items?.find(
      (x) => x.department_id === "8b803126-3bd4-4ff8-9a1e-d87c6d574221"
    )?.count;

  let allocated_public_department_count = 0;
  allocated_public_department_count =
    department?.items?.length &&
    department?.items?.find(
      (x) => x.department_id === "ab60c9c2-3d4f-4cb0-a8c0-a5ca93a8c458"
    )?.count;

  let allocated_state_guest_count = 0;
  allocated_state_guest_count =
    department?.items?.length &&
    department?.items?.find(
      (x) => x.department_id === "89e9a04d-54bd-475f-a985-6a4675615d3d"
    )?.vehicle_count;

  let department_of_secretariat_count = 0;
  department_of_secretariat_count =
    department?.items?.length &&
    department?.items?.find(
      (x) => x.department_id === "ff4678bf-da78-4faf-8800-06a302203dac"
    )?.count;

  let allocated_other_department =
    (Number(driver_count?.alloted_drivers) || 0) -
    (Number(allocated_chief_minister_count) || 0) +
    (Number(chief_minister_cell_count) || 0) +
    (Number(allocated_public_department_count) || 0) +
    (Number(allocated_state_guest_count) || 0) +
    (Number(department_of_secretariat_count) || 0);

  const public_department = () => {
    handleDepartmentRedirect("ab60c9c2-3d4f-4cb0-a8c0-a5ca93a8c458");
  };
  const handleChiefMinister = () => {
    handleDepartmentRedirect("f842edb1-a105-4560-ba43-1cd626fc70e8");
  };
  const handleMinister = (value, id) => {
    history.push(`${ROUTES.ALLOCATED_DRIVERS_DASHBOARD}/${id}`);
  };
  const handleStateGuestHouse = () => {
    handleDepartmentRedirect("89e9a04d-54bd-475f-a985-6a4675615d3d");
  };
  const handleDepartmentSecretariat = () => {
    handleDepartmentRedirect("ff4678bf-da78-4faf-8800-06a302203dac");
  };
  const handleOtherDepartment=()=>{
    handleDepartmentRedirect("other_department")
  }

  let dashboards = [];

  let title = "";
  if (
    roles?.[0]?.name === "Admin" ||
    roles?.[0]?.name === "Public Department" ||
    roles?.[0]?.name === "ASO" ||
    roles?.[0]?.name === "SO" ||
    roles?.[0]?.name === "Deputy Secretary" ||
    roles?.[0]?.name === "Under Secretary"
  ) {
    title = "";
    dashboards.push(
      {
        name: t("chief_minister_office"),
        value: allocated_chief_minister_count || 0,
        color: "#37B7C3",
        sub_color: "#088395",
        icon: (
          <Image
            source={Chief}
            size={"50px"}
            style={{ tintColor: "#088395" }}
          />
        ),
        onPress: handleChiefMinister,
      },
      {
        name: t("chief_minister_cell"),
        value: chief_minister_cell_count || 0,
        color: "#BF3131",
        sub_color: "#7D0A0A",
        icon: (
          <Image
            source={Cm_cell}
            size={"50px"}
            style={{ tintColor: "#7D0A0A" }}
          />
        ),
        onPress: handleMinister,
      },
      {
        name: t("public_department"),
        value: allocated_public_department_count || 0,
        color: "#7A9D54",
        sub_color: "#557A46",
        icon: (
          <Image
            source={Vechicle}
            size={"50px"}
            style={{ tintColor: "#557A46" }}
          />
        ),
        onPress: public_department,
      },
      {
        name: t("state_guest_house"),
        value: allocated_state_guest_count || 0,
        color: "#E7AB9A",
        sub_color: "#DF7857",
        icon: (
          <Image
            source={GuestHouse}
            size={"50px"}
            style={{ tintColor: "#DF7857" }}
          />
        ),
        onPress: handleStateGuestHouse,
      },
      {
        name: t("department_of_secretariat"),
        value: department_of_secretariat_count || 0,
        color: "#E1AFD1",
        sub_color: "#AD88C6",
        icon: (
          <Image
            source={OtherDepartment}
            size={"50px"}
            style={{ tintColor: "#AD88C6" }}
          />
        ),
        onPress: handleDepartmentSecretariat,
      },
      {
        name: "Other Departments",
        value: allocated_other_department || 0,
        color: "#C88EA7",
        sub_color: "#a57f8f",
        icon: (
          <Image
            source={OtherDepartment}
            size={"50px"}
            style={{ tintColor: "#a57f8f" }}
          />
        ),
        onPress: handleOtherDepartment,

      }
    );
  }
  return (
    <Box>
      <Box alignItems={"start"}>
        <Button onClick={handleGoBack}>
          <IoMdArrowRoundBack size={"20px"} />
        </Button>
      </Box>
      <Box width="100%" alignItems="center">
        <Box alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <Text fontSize="3xl" bold pb={4} color={"black"}>
            {title}
          </Text>
          <SimpleGrid
            columns={{
              base: 1,
              xs: 1,
              md: 3,
              sm: 2,
              lg: 4,
              xl: 4,
              "2xl": 5,
            }}
            spacingY={"27px"}
            spacingX={21}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {dashboards?.map((option) => {
              return (
                <Box
                  size="100px"
                  backgroundColor={option.color}
                  style={{
                    cursor: "pointer",
                    width: "220px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Pressable onPress={option.onPress}>
                    <VStack
                      justifyContent={"space-between"}
                      alignItems={"space-around"}
                    >
                      <HStack
                        space={"10px"}
                        justifyContent={"space-around"}
                        // padding={"10px"}
                      >
                        <Box h={"80px"} justifyContent={"center"}>
                          {option.icon}
                        </Box>
                        <Box h={"80px"} justifyContent={"center"}>
                          <Text
                            color={"white"}
                            fontSize={"26px"}
                            // fontStyle={"italic"}
                            fontWeight={600}
                          >
                            {option?.value}
                          </Text>
                        </Box>
                      </HStack>
                      <Box
                        backgroundColor={option?.sub_color}
                        justifyContent={"center"}
                        width={"100%"}
                        height={"30px"}
                        style={{
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <Text
                          textAlign={"center"}
                          alignItems={"center"}
                          color={"white"}
                          fontSize={"14px"}
                          fontWeight={500}
                        >
                          {option?.name}
                        </Text>
                      </Box>
                    </VStack>
                  </Pressable>
                </Box>
              );
            })}
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};
export default AllocatedDriverDashboard;
