import { dashboardReducer } from "./dashboard";
import { dashboard_fuelReducer } from "./dashboard_fuel";
import { department_dashboardReducer } from "./department_dashboard";
import { technicalPersonDashboardReducer } from "./technical_person_dashboard";
import { driver_dashboard_count_Reducer } from "./driver_dashboard_count";
import { pass_year_list_Reducer } from "./pass_year_list";
import { pass_dashboard_Reducer } from "./pass_dashboard_count";
import { department_driver_dashboard_Reducer } from "./department_driver_dashboard";
import { department_driver_classification_Reducer } from "./department_driver_classification_count";
export const dashboardReducers = {
  dashboard: dashboardReducer,
  dashboard_fuel: dashboard_fuelReducer,
  department_dashboard: department_dashboardReducer,
  technicalPersonDashboard: technicalPersonDashboardReducer,
  driver_dashboard_count: driver_dashboard_count_Reducer,
  pass_year_list: pass_year_list_Reducer,
  pass_dashboard_count: pass_dashboard_Reducer,
  department_driver_dashboard: department_driver_dashboard_Reducer,
  department_driver_classification:department_driver_classification_Reducer
};
export * from "./dashboard";
export * from "./dashboard_fuel";
export * from "./department_dashboard";
export * from "./technical_person_dashboard";
export * from "./driver_dashboard_count";
export * from "./pass_year_list";
export * from "./pass_dashboard_count";
export * from "./department_driver_dashboard";
export * from "./department_driver_classification_count";
