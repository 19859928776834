import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import TamilNaduLogo from "@assets/images/tamilnadu_logo-removebg.png";
import PassLogo from "@assets/images/SECRETARIAT--TRANSIT CAR PASS--2023--KIND-II-Red.jpg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Greentick from "@assets/images/checked.png";
import QRCode from "qrcode";
import { useParams } from "react-router-dom";

const GeneratePass = (props) => {
  const { pass_item } = props;
  const { t } = useTranslation();
  const [qrCodeData, setQrCodeData] = useState("");

  const valid_till = moment(Number(pass_item?.valid_till)).format("DD/MM/YYYY");

  useEffect(() => {
    if (pass_item?.id) {
      // Ensure that pass_item.id is valid before generating QR code
      // const url = encodeURI(
      //   // `${window.location.origin}/generate-pass-view/${pass_item.id}`
      //   `https://pvm-sit.vertace.org/generate-pass-view/${pass_item.id}`
      // );
      const url = `https://pvm-sit.vertace.org/generate-pass-view/${pass_item.id}`;

      QRCode.toDataURL(url, { width: 100 }, (err, url) => {
        if (err) {
          console.error("QR code generation error:", err);
        } else {
          setQrCodeData(url); // Set the generated QR code
        }
      });
    }
  }, [pass_item?.id]);

  // useEffect(() => {
  //   const generateQRCode = async () => {
  //     if (pass_item?.id) {
  //       try {
  //         // Generate the QR code with a correct URL pointing to the pass view page
  //         const passUrl = `${window.location.origin}/generate-pass-view/${pass_item.id}`;
  //         console.log("passUrl", passUrl);

  //         const url = await QRCode.toDataURL(passUrl, { width: 100 });
  //         setQrCodeData(url); // Set the generated QR code URL in state
  //       } catch (err) {
  //         console.error("QR code generation error:", err);
  //       }
  //     }
  //   };

  //   generateQRCode(); // Call the async function to generate the QR code
  // }, [pass_item?.id]);

  return (
    <Document>
      <Page size="A5" orientation="portrait" style={styles.page}>
        <View style={styles.headerSpace}>
          <View style={styles.headAgreement}>
            <Image style={styles.image} src={TamilNaduLogo} />
            <View style={styles.pass_content}>
              <View>
                <Text style={styles.head_one}>VP NO.</Text>
              </View>
              <View>
                <Text style={styles.text_details}>
                  {pass_item?.pass_number}
                </Text>
              </View>
            </View>
          </View>
          <Image style={styles.image1} src={PassLogo} />
          <View style={styles.description_pass}>
          {pass_item?.name && (
              <View style={styles.pass_content_centered}>
                <Text style={styles.text_style}>NAME{":"}</Text>
                <Text style={styles.description}>
                  {pass_item?.name
                    ? pass_item?.name
                    : "-"}
                </Text>
              </View>
            )}
            {pass_item?.designation?.name && (
              <View style={styles.pass_content_centered}>
                <Text style={styles.text_style}>DESIGNATION{":"}</Text>
                <Text style={styles.description1}>
                  {pass_item?.designation?.name
                    ? pass_item?.designation?.name
                    : "-"}
                </Text>
              </View>
            )}
            {pass_item?.department?.name && (
              <View style={styles.pass_content_centered}>
                <Text style={styles.text_style}>DEPARTMENT{":"}</Text>
                <Text style={styles.description1}>
                  {pass_item?.department?.name
                    ? pass_item?.department?.name
                    : "-"}
                </Text>
              </View>
            )}
            {pass_item?.vehicle_number && (
              <View style={styles.pass_content_centered}>
                <Text style={styles.text_style}>VEHICLE NO{":"}</Text>
                <Text style={styles.description}>
                  {pass_item?.vehicle_number ? pass_item?.vehicle_number : "-"}
                </Text>
              </View>
            )}
            {pass_item?.vehicle_make && (
              <View style={styles.pass_content_centered}>
                <Text style={styles.text_style}>VEHICLE MAKE{":"}</Text>
                <Text style={styles.description}>
                  {pass_item?.vehicle_make ? pass_item?.vehicle_make : "-"}
                </Text>
              </View>
            )}
            {valid_till && (
              <View style={styles.pass_content_centered}>
                <Text style={styles.text_style}>VALIDITY UPTO{":"}</Text>
                <Text style={styles.description}>
                  {valid_till ? valid_till : "-"}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.qr_code_center}>
            <View style={styles.qr_code}>
              {qrCodeData && <Image src={qrCodeData} style={styles.qrImage} />}
            </View>
            <View style={styles.content_left}>
              <View>
                <Text style={styles.font_style}>
                Under Secy / Dy.Secy to Govt
                </Text>
              </View>
              <View>
                <Text style={styles.font_style}>Public (MV) Department</Text>
              </View>
              <View>
                <Text style={styles.font_style}>Secretariat, Chennai-9</Text>
              </View>
              <View>
                <Image style={styles.image2} src={Greentick} />
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  headAgreement: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    marginTop: "50px",
  },
  image: {
    width: "55px",
    height: "65px",
    alignItems: "center",
  },
  image1: {
    width: "100%",
    zIndex: "-1",
    alignItems: "center",
    position: "absolute",
  },
  headerSpace: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  head_one: {
    fontSize: "12px",
  },
  text_details: {
    fontSize: "12px",
    textAlign: "center",
    fontWeight: "light",
    textDecoration: "underline",
  },
  pass_content: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    marginBottom: "6px",
  },
  pass_content_centered: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start", // Center the content horizontally
    width: "100%",
    marginLeft: "28%",
    gap: "10px",
    // marginBottom: "3px",
  },
  content_left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    gap: "5px",
    marginLeft: "40px",
  },
  qr_code_center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    // gap: "5px",
    marginTop:"5px"
  },
  qr_right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    marginLeft: "100px",
  },
  text_style: {
    alignItems: "center",
    fontSize: "10px",
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: "5px",
    width:"80px"
  },
  font_style: {
    fontSize: "8px",
    fontWeight: "bold",
    textAlign: "center",
    fontWeight: "bold",
  },
  description: {
    // width:"50%",
    fontSize: "10px",
    textAlign: "center",
    fontWeight: "light",
    // textDecoration: "underline",
  },
  description1: {
    width:"40%",
    fontSize: "10px",
    // textAlign: "center",
    fontWeight: "light",
    // textDecoration: "underline",
  },
  description_pass: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    marginTop:"5px",
    marginRight:"15px"
  },
  image2: {
    alignItems: "center",
    width: "15%",
    height: "45px",
    zIndex: "1",
    opacity: "0.5",
    position: "absolute",
    left: "100px",
    bottom: "8px",
  },
  qr_code: {
    textAlign: "left",
    marginLeft: "80px",
  },
  qrImage: {
    width: 40,
    height: 40,
  },
});

export default GeneratePass;
