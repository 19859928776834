import React, { useEffect, useState } from "react";
import { Box, Text, VStack, HStack, Image, Center } from "native-base";
import moment from "moment";
import Greentick from "@assets/images/Green-Tick-PNG-Photos.png";
import QRCode from "qrcode";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_one_gatepass, getOnegatepassSelector } from "@services/redux";

const GenerateTransitPassView = (props) => {
  //   const { passItem } = props;
  const [qrCodeData, setQrCodeData] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();

  const { item: passItem } = useSelector(getOnegatepassSelector);

  const valid_till = moment(Number(passItem?.valid_till)).format("DD/MM/YYYY");

  useEffect(() => {
    dispatch(get_one_gatepass({ id }));
  }, [id]);

  useEffect(() => {
    if (passItem?.id) {
      // Generate the QR code as a base64 image
      const passUrl = `${window.location.origin}/generate-transit-pass-view/${passItem.id}`;
      QRCode.toDataURL(passUrl, { width: 100 }, (err, url) => {
        if (err) {
          console.error(err);
          return;
        }
        setQrCodeData(url);
      });
    }
  }, [passItem]);

  return (
    <Box flex={1} p={4} bg="white">
      {/* V.P. No. Section */}
      {/* <HStack justifyContent="space-between" mb={5}> */}
      <Center>
        <Text fontSize="2xl" fontWeight="bold">
          V.P. No. {passItem?.pass_number}
        </Text>
      </Center>
      {/* {qrCodeData && (
          <Image
            source={{ uri: qrCodeData }}
            alt="QR Code"
            size="70px"
            resizeMode="contain"
          />
        )} */}
      {/* </HStack> */}

      {/* Title Section */}
      <VStack alignItems="center" mb={5}>
        <Text fontSize="3xl" fontWeight="bold" underline>
          SECRETARIAT CAR PASS
        </Text>
        <Text fontSize="3xl" fontWeight="bold" underline>
          TRANSIT ONLY
        </Text>
      </VStack>

      {/* Main Content */}
      <Center>
        <VStack space={3} mb={10}>
          <Text
            fontSize="xl"
            textAlign={"justify"}
            width={{ xs: "300px", lg: "500px" }}
          >
            Permit:{" "}
            <Text fontWeight="bold" underline>
              {passItem?.vehicle_make}
            </Text>{" "}
            Vehicle No:{" "}
            <Text fontWeight="bold" underline>
              {passItem?.vehicle_number}
            </Text>
            of Thiru/Tmt/M/s:{" "}
            <Text fontWeight="bold" underline>
              {passItem?.name}
            </Text>{" "}
            Secretariat Campus. Parking of the vehicle will be for Transit entry
            into the only at "Parking Area" in Marina Garden (opposite to
            Secretariat). This Transit entry pass is valid up to{" "}
            <Text fontWeight="bold" underline>
              {valid_till}
            </Text>
            .
          </Text>
        </VStack>
      </Center>

      {/* Footer Section */}
      {/* <VStack alignItems="center" space={2}>
        <Text fontSize="md" fontWeight="bold">
          Under Secretary to Government,
        </Text>
        <Text fontSize="md">Public(MV) Department,</Text>
        <Text fontSize="md">Secretariat, Chennai-9</Text>
        <Image
          source={Greentick}
          alt="Green Tick"
          size="50px"
          resizeMode="contain"
          style={{
            opacity: 0.5,
            position: "absolute",
            top: 50,
          }}
        />
        <Text fontSize="md" mt={5}>
          Parking inside Secretariat campus is strictly prohibited
        </Text>
      </VStack> */}
    </Box>
  );
};

export default GenerateTransitPassView;
